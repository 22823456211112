@use "../base/breakpoints";

/* --------------- header --------------- */

#page-header .logo {
  margin-left: 1.2rem;
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}

#jenkins-home-link {
  position: relative;
}

#jenkins-head-icon {
  height: 2.5rem;
}

#jenkins-name-icon {
  margin-left: 0.25rem;
}

/* -------------------------------------- */

.app-page-body {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 0 auto;
}

.app-page-body__sidebar {
  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: breakpoints.$tablet-breakpoint) {
    &--sticky {
      position: sticky;
      top: 44px;
      align-self: flex-start;
    }
  }
}

#page-body.clear::after {
  clear: both;
  content: "";
  display: table;
}

#side-panel {
  flex-shrink: 0;
}

#main-panel {
  padding: var(--section-padding);
  display: inline-block;
  width: 100%;
}

.app-page-body--one-column {
  --form-item-max-width: calc(85vw - 4rem);

  @media screen and (width <= 1200px) {
    --form-item-max-width: 100%;
  }

  max-width: 85vw;

  #main-panel {
    width: 100vw;
  }

  .jenkins-section {
    max-width: unset;
  }

  @media (width <= 1200px) {
    max-width: unset;
  }

  margin: auto;
}

body.two-column #main-panel {
  width: calc(100% - var(--side-panel-width));
  flex: 1;
  display: block;
}

body.full-screen {
  padding: 0;
}

body.full-screen #main-panel {
  padding: 0;
}

body.two-column #side-panel {
  width: var(--side-panel-width);
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (max-width: breakpoints.$tablet-breakpoint) {
  body.two-column #page-body {
    flex-wrap: wrap;
  }

  body.two-column #side-panel {
    width: 100%;
    border-right: none;
  }

  body.two-column #main-panel {
    margin-left: 0;
    width: 100%;
  }
}

.app-project-status-table {
  width: 100%;
  overflow-x: auto;
}

@media (width >= 1170px) {
  body.two-column #main-panel {
    width: calc(
      100% - calc(var(--side-panel-width) + calc(var(--section-padding) * 2))
    );
  }
}

// Clearfixes extracted from responsive-grid.css as they essential
.clearfix::before,
.clearfix::after,
.container::before,
.container::after,
.container-fluid::before,
.container-fluid::after,
.row::before,
.row::after,
.form-horizontal .form-group::before,
.form-horizontal .form-group::after,
.btn-toolbar::before,
.btn-toolbar::after,
.btn-group-vertical > .btn-group::before,
.btn-group-vertical > .btn-group::after,
.nav::before,
.nav::after,
.navbar::before,
.navbar::after,
.navbar-header::before,
.navbar-header::after,
.navbar-collapse::before,
.navbar-collapse::after,
.pager::before,
.pager::after,
.panel-body::before,
.panel-body::after,
.modal-footer::before,
.modal-footer::after {
  display: table;
  content: " ";
}

.clearfix::after,
.container::after,
.container-fluid::after,
.row::after,
.form-horizontal .form-group::after,
.btn-toolbar::after,
.btn-group-vertical > .btn-group::after,
.nav::after,
.navbar::after,
.navbar-header::after,
.navbar-collapse::after,
.pager::after,
.panel-body::after,
.modal-footer::after {
  clear: both;
}
