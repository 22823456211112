.app-progress-bar {
  --color: var(--accent-color);

  height: 12px;
  width: 104px;
  padding: 2px;
  border-radius: 6px;
  background-color: color-mix(
    in sRGB,
    var(--text-color-secondary) 25%,
    transparent
  );
  display: block;
  opacity: 1 !important;

  &--error > span {
    --color: var(--error-color);
  }

  &--unknown {
    width: 100%;
  }

  &--large {
    height: 24px;
    width: 208px;
    padding: 4px;
    border-radius: 12px;

    & > span {
      border-radius: 8px !important;
    }
  }

  & > span {
    height: 100%;
    min-width: 8%;
    background-color: var(--color);
    display: block;
    border-radius: 4px;
  }

  &--animate {
    background-image: linear-gradient(
      -45deg,
      var(--background) 25%,
      transparent 25%,
      transparent 50%,
      var(--background) 50%,
      var(--background) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 25px 25px;
    animation: progress-bar 5s linear infinite;

    @keyframes progress-bar {
      0% {
        background-position: 0 0;
      }

      100% {
        background-position: 25px 25px;
      }
    }
  }

  &:link {
    &:hover {
      opacity: 0.75 !important;
    }

    &:active {
      opacity: 0.5 !important;
    }
  }
}
