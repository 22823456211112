@use "../abstracts/mixins";

.jenkins-command-palette__dialog {
  background: none;
  border: none;
  height: 100vw !important;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  user-select: none;

  &::backdrop {
    background: var(--command-palette-backdrop-background);
    backdrop-filter: contrast(0.7) brightness(0.9) saturate(1.25) blur(3px);
    animation: jenkins-dialog-backdrop-animate-in 0.1s linear;
  }

  &[open] {
    animation: command-palette-animate-in 0.1s cubic-bezier(0, 0.68, 0.5, 1.5);
  }

  &[closing] {
    animation: command-palette-animate-out 0.1s linear;

    &::backdrop {
      animation: jenkins-dialog-backdrop-animate-out 0.1s linear;
    }
  }
}

@keyframes command-palette-animate-in {
  from {
    translate: 0 4px;
    scale: 98.5%;
    opacity: 0;
    transform: rotateX(30deg);
  }
}

@keyframes command-palette-animate-out {
  to {
    scale: 98.5%;
    opacity: 0;
  }
}

.jenkins-command-palette__wrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: scroll;
  padding-top: 20vh;
}

.jenkins-command-palette {
  position: relative;
  width: 50vw;
  min-width: 400px;
  max-width: 650px;
  color: var(--text-color);
  pointer-events: auto;
  margin: 0 auto 20vh;

  &__search {
    --search-bar-height: 3rem !important;

    background: transparent;
    box-shadow: var(--command-palette-inset-shadow);
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    transition: var(--standard-transition);
    z-index: 10;
    backdrop-filter: var(--command-palette-results-backdrop-filter);
    max-width: unset;

    input {
      background: transparent !important;
      border-radius: inherit;

      &::before,
      &::after {
        border-radius: inherit;
      }
    }

    &::before {
      content: unset;
    }
  }

  &__results-container {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    backdrop-filter: var(--command-palette-results-backdrop-filter);
    box-shadow: var(--command-palette-inset-shadow);
    // If set to 0, Safari won't always show the backdrop-filter
    height: 1px;
    transition: height var(--standard-transition);
    overflow: hidden;
    will-change: height;
  }

  &__results {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    &__heading {
      font-weight: 500;
      font-size: 0.875rem;
      margin: 0;
      padding: 0.75rem 0.75rem 0.625rem;
      color: var(--text-color-secondary);

      &:not(:first-of-type) {
        padding-top: 2rem;
      }
    }

    &__item {
      @include mixins.item;

      --item-background--hover: color-mix(
        in sRGB,
        var(--text-color-secondary) 15%,
        transparent
      );
      --item-background--active: color-mix(
        in sRGB,
        var(--text-color-secondary) 20%,
        transparent
      );

      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: transparent;
      padding: 0.75rem;
      border-radius: 0.5rem;
      cursor: pointer;
      color: var(--text-color) !important;
      transition: var(--standard-transition);
      box-shadow: 0 0 0 10px transparent;
      border: none;
      outline: none;

      &--hover {
        &::before {
          background-color: var(--item-background--hover);
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.375rem;
        height: 1.375rem;
        margin-right: 0.75rem;
        overflow: hidden;
        pointer-events: none;
        color: var(--text-color);

        svg,
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      &__description {
        opacity: 0.5;
        margin-left: 1ch;
      }

      &__chevron {
        position: absolute;
        top: calc(50% - 8px);
        right: 12.5px;
        width: 16px;
        height: 16px;
        opacity: 0.5;
      }
    }
  }

  &__info {
    font-size: 0.875rem;
    margin: 0;
    padding: 0 14px;
    line-height: 46px;
    color: var(--text-color);

    span {
      color: var(--text-color-secondary);
    }
  }
}
