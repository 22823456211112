.jenkins-app-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--section-padding);

  .jenkins-app-bar__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 2.25rem;
  }

  .jenkins-app-bar__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--section-padding);
    min-height: 2.25rem;
    gap: 0.75rem;

    .jenkins-search {
      min-width: 260px;
    }
  }

  &--sticky {
    position: sticky;
    top: 40px;
    padding-top: var(--section-padding);
    margin-top: calc(var(--section-padding) * -1);
    z-index: 2;

    &::before,
    &::after {
      content: "";
      position: absolute;
      inset: 0 calc(var(--section-padding) * -1)
        calc(var(--section-padding) * -1);
      z-index: -1;
      pointer-events: none;
    }

    &::before {
      background: var(--background);
      mask-image: linear-gradient(black 70%, transparent);
      opacity: 0.55;

      @supports not (backdrop-filter: blur(15px)) {
        opacity: 1;
      }
    }

    &::after {
      backdrop-filter: blur(15px);
      mask-image: linear-gradient(black 50%, transparent);
    }
  }

  h1,
  h2 {
    margin: 0;
    font-size: 1.5rem;
  }

  &__subtitle {
    color: var(--text-color-secondary);
    margin-left: 0.5ch;
  }
}

.jenkins-build-caption {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: start !important;
  gap: 1rem;
  max-width: 1200px;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    width: 2rem !important;
    height: 2rem !important;
  }
}
